<template>
  <div style="background-color: #EBEED7;">
   <section>
      <article
        style="padding-top: 160px;">
      
        <div class="container" style="margin: 50px auto; text-align: left">
          <div class="article_intro">
            <p class="locus_word_style">
                    <small>KINAWATAKA WOMEN INITIATIVES</small>
                  </p>
            <h2 style="font-weight: 600; font-size: 2em">
              Explore our amazing products.
            </h2>
            <p style="">
                Our products are made from the 
                <strong>recycled straws</strong> and <strong>palm leaves</strong>.
            </p>
            <p style="">
                
            </p>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="mb-5">
        <div class="container">
            <div class="products_grid">
                <div v-for="product in products" :key="product.id">
                    <div>
                        <img :src="product.image" class="w-100">
                    </div>
                    <div>
                        <span>{{ product.name }}</span>
                    </div>
                </div>
            </div>
        </div>
      </article>
    </section>
    
  </div>
</template>

<script>
    export default {

    data() {
        return {
            products: [
                {
                id: "1",
                name: "Mat",
                image: require("../assets/images/products/mat.jpg")
                },
                {
                id: "2",
                name: "Mat 2",
                image: require("../assets/images/products/mat2.jpg")
                },
                {
                id: "3",
                name: "Table Mat",
                image: require("../assets/images/products/table_mats.jpg")
                },
                {
                id: "4",
                name: "Door Mat",
                image: require("../assets/images/products/door_mat.jpg")
                },
                {
                id: "5",
                name: "Glass cover",
                image: require("../assets/images/products/glass_cover.jpg")
                },
                {
                id: "6",
                name: "Safari Bag",
                image: require("../assets/images/products/safari_bag.jpg")
                },
                {
                id: "7",
                name: "Shopping bag medium",
                image: require("../assets/images/products/shopping_bag_medium.jpg")
                },
                {
                id: "8",
                name: "Shopping bag small",
                image: require("../assets/images/products/shopping_bag_mini.jpg")
                },
                {
                id: "9",
                name: "Hand laggage",
                image: require("../assets/images/products/hand_laggage.jpg")
                },
                {
                id: "10",
                name: "Hand bag",
                image: require("../assets/images/products/hand_bag.jpg")
                },
                {
                id: "11",
                name: "Shopping bag small",
                image: require("../assets/images/products/shopping_bag_mini.jpg")
                },
                {
                id: "12",
                name: "Cross country bag",
                image: require("../assets/images/products/cross_country_bag.jpg")
                },
                {
                id: "13",
                name: "Bottle bag",
                image: require("../assets/images/products/bottle_bag.jpg")
                },
                {
                id: "14",
                name: "Oval bag",
                image: require("../assets/images/products/oval_bag.jpg")
                },
                {
                id: "15",
                name: "Purse bag",
                image: require("../assets/images/products/purse_bag.jpg")
                },
                {
                id: "16",
                name: "Coin bag",
                image: require("../assets/images/products/coin_bag.jpg")
                },
                {
                id: "17",
                name: "Ladies hand bag",
                image: require("../assets/images/products/ladies_hand_bag.jpg")
                },
                {
                id: "18",
                name: "File folder",
                image: require("../assets/images/products/file_folder.jpg")
                },
            ],
        };
    },

    methods: {
        
    },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .locus_word_style {
        font-size: 0.5em; font-weight: 300;
        word-spacing: 8px;
        letter-spacing: 5px;
    }
    .article_intro {
        width: 80%;
    }

    .products_grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 10px;
    }

    .products_grid > div{
        border-radius: 10px;
    }
    .products_grid > div>div:first-child{
        height: 300px;
        background-color:  white;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .products_grid > div>div:first-child img{
        height: 100%;
        object-fit: contain;
    }
    .products_grid > div>div:nth-child(2){
        text-align: center;
        padding: 20px 10px;
        font-size: 1.0em;
        font-weight: 600;
        background-color: #eee;
        border-radius: 0px 0px 10px 10px;
    }

    /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) { }

    /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { }

    /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
    @media (min-width: 481px) and (max-width: 767px) {
        .article_intro {
            width: 100%;
        }
    }

    /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
    @media (min-width: 320px) and (max-width: 480px) {
        .article_intro {
            width: 100%;
        }

    .products_grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }
    }
</style>
